.page-content {
	@apply flex flex-col gap-5 py-5 px-5 sm:px-10 lg:px-52 max-w-screen-xl mx-auto pb-20 pt-32;
}

a {
	@apply cursor-pointer;
}

.title {
	@apply text-6xl;
}

.heading-1 {
	@apply text-2xl font-bold;
}

.heading-2 {
	@apply text-xl;
}

.heading-3 {
	@apply text-lg;
}

.label {
	@apply text-base font-semibold;
}

.body {
	@apply text-base font-normal;
}

.link {
	@apply hover:underline underline-offset-2 text-blue-600;
}
